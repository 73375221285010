// calebaren.github.io
import PageOne from './MultistepFormPages/PageOne';
import PageTwo from './MultistepFormPages/PageTwo';
import PageThree from './MultistepFormPages/PageThree';
import PageFour from './MultistepFormPages/PageFour';
import PageFive from './MultistepFormPages/PageFive';
import PageSix from './MultistepFormPages/PageSix';


const PagesList = {
    PageOne,
    PageTwo,
    PageThree,
    PageFour,
    PageFive,
    PageSix,
};

export default PagesList;