// calebaren.github.io
import FirstPage from './FormFirstPage';
import MiddlePage from './FormMiddlePage';
import SubmitPage from './FormSubmitPage';

const FormPages = {
    FirstPage,
    MiddlePage,
    SubmitPage,
};

export default FormPages;